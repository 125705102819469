/* General styles for the entire app */
html, body {
    height: 100%;
    margin: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: white;
    color: black;
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
  
  h1 {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
  }

  .text-red {
    color: red; /* O el color que prefereixis */
}

  .text-green {
    color: #209385; 
  }
  
  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  p {
    margin-top: 10px; 
    margin-bottom: 10px;
  }

  .centered-text {
    text-align: center !important;
}

  .right-aligned-text {
    text-align: right;
  }
  
  p.small {
    font-size: 16px; /* Lletra una mica més petita */
    margin-bottom: 0; /* Sense espai entre l'última línia i la següent */
    margin-top: 0; /* Sense espai entre l'última línia i la següent */
  }
  
  /* Link styles */
a {
  color: #19756a;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Estils per a textos de dreta a esquerra */
.rtl {
  direction: rtl;
  text-align: right;
}

/* Estils per a textos d'esquerra a dreta */
.ltr {
  direction: ltr;
  text-align: left;
}

ul {
  list-style-type: none; /* Treu els punts de llista */
  padding-left: 0; /* Elimina indentació per defecte */
}

ul ul { /* Aplica només als nivells de subcategories */
  padding-left: 20px; /* Indentació per a subcategories */
}

ul ul ul { /* Aplica als sub-subcategories */
  padding-left: 40px;
}

/* Categories principals */
.category-title {
  color: #209385; /* Verd per destacar */
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 20px;
  display: block;
  border-bottom: 2px solid #209385; /* Línia per destacar */
  padding-bottom: 5px;
}

/* Subcategories */
.subcategory-title {
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 15px;
  color: #2C3E50; /* Color més suau */
}

/* Sub-subcategories */
.subsubcategory-title {
  font-weight: bold;
  font-size: 1.1em;
  margin-top: 10px;
  color: #555; /* Encara més suau */
  font-style: italic;
}

/* Recursos finals */
li {
  margin-bottom: 2px;
  padding: 5px 0;
  line-height: 1.2;
}