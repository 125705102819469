/* 🔹 Contenidor principal */
.hierarchical-container {
    border: 2px solid #209385;
    padding: 30px;
    border-radius: 8px;
    min-height: 200px;
    background-color: white;
    max-width: 700px;
    margin: 0 auto;
  }
  
  /* 🔹 Llista jeràrquica */
  .hierarchy-tree {
    list-style-type: none;
    padding-left: 20px;
  }
  
  /* 🔹 Estils per a categories i subcategories */
  .hierarchy-item {
    margin-bottom: 5px;
  }
  
  .hierarchy-category {
    font-weight: bold;
    color: #209385;
  }
  
  .hierarchy-subcategory {
    font-weight: bold;
    font-style: italic;
    color: #209385;
  }
  
  .hierarchy-sub-subcategory {
    font-style: italic;
    color: #209385;
  }
  
  .hierarchy-concept {
    color: black;
  }
  
  /* 🔹 Contenidor per la comparació */
  .hierarchical-comparison {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
  }
  
  /* 🔹 Contenidors individuals */
  .hierarchy-box {
    flex: 1;
    background-color: white;
    border: 1px solid #209385;
    padding: 15px;
    border-radius: 8px;
  }
  
  /* 🔹 Inputs per afegir nous elements */
  .input-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  /* 🔹 Textarea */
  .hierarchy-textarea {
    width: 100%;
    min-height: 80px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
  }
  
  /* 🔹 Botó d'exportació */
  .export-json-container {
    text-align: center;
    margin-top: 15px;
  }
  
  /* 🔹 Títols centrats */
  .hierarchy-title {
    text-align: center;
  }
  
  /* 🔹 Llista jeràrquica */
  .hierarchy-tree {
    list-style-type: none;
    padding-left: 20px;
  }

  .hierarchy-navigation {
    align-items: center;
    text-align: center;
  }